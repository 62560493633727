/* @import "../node_modules/react-toastify/dist/ReactToastify.min.css"; */
body {
  margin: 0;
  font-family: "Avenir" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
/* @font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./assets/FontFamilies/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("./assets/FontFamilies/Montserrat-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/FontFamilies/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/FontFamilies/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/FontFamilies/Montserrat-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/FontFamilies/Montserrat-SemiBold.otf") format("truetype");
}
@font-face {
  font-family: "Lato-Black_0";
  src: url("./assets/FontFamilies/Lato-Black_0.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Regular_0";
  src: url("./assets/FontFamilies/Lato-Regular_0.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold_0";
  src: url("./assets/FontFamilies/Lato-Bold_0.ttf") format("truetype");
} */
@font-face {
  font-family: "Avenir";
  src: url("./assets/FontFamilies/Avenir.ttc") format("truetype");
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.css-nvf14r-ToastContainer {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 8px;
  position: fixed;
  z-index: 10000 !important;
  top: 0;
  right: 0;
}
.multipleDropdown fieldset {
  border: 0px;
}
